import { useMemo } from 'react'
import { UebergabenNames } from './UebergabenNames'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export function uebergabenToDisplay(roles: undefined | Array<RoleEnumType>): Array<UebergabenNames> {
  const uebergabenToDisplay: Array<UebergabenNames> = []
  if (roles == undefined) {
    return uebergabenToDisplay
  }
  if (
    roles.includes('Admin') ||
    roles.includes('Vertrieb') ||
    roles.includes('Innendienst') ||
    roles.includes('Projektierung')
  ) {
    uebergabenToDisplay.push('VertriebAnEngineering')
  }
  if (
    roles.includes('Admin') ||
    roles.includes('Vertrieb') ||
    roles.includes('Innendienst') ||
    roles.includes('Projektierung')
  ) {
    uebergabenToDisplay.push('EngineeringAnVertrieb')
  }
  if (roles.includes('Admin') || roles.includes('Vertrieb') || roles.includes('Innendienst') || roles.includes('PMO')) {
    uebergabenToDisplay.push('VertriebAnPMO')
  }
  if (
    roles.includes('Admin') ||
    roles.includes('PMO') ||
    roles.includes('ServiceUndWartung') ||
    roles.includes('ProjektSupport')
  ) {
    uebergabenToDisplay.push('PMOAnS&W')
  }
  return uebergabenToDisplay
}

export function useUebergabenToDisplay(roles: undefined | Array<RoleEnumType>): Array<UebergabenNames> {
  return useMemo(() => uebergabenToDisplay(roles), [roles])
}
