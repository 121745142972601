import { View } from 'react-native'
import { H1 } from './TextComponents'
import { dsv } from '../styles/defaults'
import { ChildrenProp } from '../types'
import { PropsWithChildren } from 'react'
import { Div } from '@expo/html-elements'

interface CardProps {
  customStyle?: object
}

export function Card({ customStyle, children }: PropsWithChildren<CardProps>) {
  return (
    <View
      style={{
        backgroundColor: dsv.colors.connectGrau,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: dsv.colors.connectGrau,
        borderRadius: 3,
        ...customStyle,
      }}
    >
      {children}
    </View>
  )
}

interface CardHeaderProps extends ChildrenProp {}

export function CardHeader({ children }: CardHeaderProps) {
  return (
    <Div
      style={{
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: dsv.colors.connectGrau7,
        padding: 12,
        marginTop: 8,
        marginBottom: 0,
      }}
    >
      <H1 style={{ color: dsv.colors.connectGrau7, marginBottom: 5 }}>{children}</H1>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, height: 8, width: 50 }} />
    </Div>
  )
}

export function CardJsxHeader({ children }: CardHeaderProps) {
  return (
    <Div
      style={{
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: dsv.colors.connectGrau7,
        padding: 12,
        marginTop: 8,
        marginBottom: 0,
      }}
    >
      {children}
    </Div>
  )
}

interface CardBodyProps {
  customStyle?: object
}

type CardActionProps = Record<string, unknown>

export function CardBody({ children, customStyle }: PropsWithChildren<CardBodyProps>) {
  return <View style={{ padding: 12, minHeight:300, ...customStyle }}>{children}</View>
}

export function CardAction({ children }: PropsWithChildren<CardActionProps>) {
  return <View style={{ padding: 12 }}>{children}</View>
}
