import formLabels_de from './de/labels.json'
import formNotes_de from './de/notes.json'
import tableHeaders_de from './de/tableHeaders.json'

import enumTranslations_de from './de/enum.json'

const translationPrefixes = {
  labels: 'form_label_',
  notes: 'form_note_',
  tableHeaders: 'table_header_',
} as const

export function addPrefix<TTyp extends keyof typeof translationPrefixes, TKey extends string>(
  typ: TTyp,
  key: TKey
): `${(typeof translationPrefixes)[TTyp]}${TKey}` {
  return `${translationPrefixes[typ]}${key}`
}

export function addKeyPrefix<
  TTyp extends keyof typeof translationPrefixes,
  TKeys extends string,
  TValues extends string,
>(typ: TTyp, obj: Record<TKeys, TValues>) {
  const entries = Object.entries(obj)
  const prefixedEntries = entries.map(([key, value]) => [addPrefix(typ, key), value])

  const prefixedObj = Object.fromEntries(prefixedEntries) as {
    [key in TKeys as ReturnType<typeof addPrefix<TTyp, key>>]: (typeof obj)[key]
  }

  return prefixedObj
}

export type CanonicalFormLabels = keyof typeof formLabels_de

// TODO: this might not be save, as typescript is TS is not strictly typed
//       But they are loaded from json, so it *should* be fine I guess
export const canonicalFormLabels = Object.keys(formLabels_de) as CanonicalFormLabels[]

export type FormLabelKeys = ReturnType<typeof addPrefix<'labels', CanonicalFormLabels>>

export function asFormLabel(candidate: string): CanonicalFormLabels | undefined {
  if ((canonicalFormLabels as string[]).includes(candidate)) {
    return candidate as CanonicalFormLabels
  }
  return undefined
}

export type OnlyValideLabels<T> = T extends CanonicalFormLabels ? T : never

export type CanonicalFormNotes = keyof typeof formNotes_de
export const canonicalFormNotes = Object.keys(formNotes_de) as CanonicalFormNotes[]
export type FormNotesKeys = ReturnType<typeof addPrefix<'notes', CanonicalFormNotes>>


export type CanonicalTableHeaders = keyof typeof tableHeaders_de
export const canonicalTableHeaders = Object.keys(tableHeaders_de) as CanonicalTableHeaders[]
export type TableHeadersKeys = ReturnType<typeof addPrefix<'notes', CanonicalTableHeaders>>

export type CanonicalEnumTranslations = keyof typeof enumTranslations_de
export const canonicalEnumTranslations = Object.keys(enumTranslations_de) as CanonicalEnumTranslations[]
export type EnumTranslationsKeys = ReturnType<typeof addPrefix<'notes', CanonicalEnumTranslations>>

export function BuildTranslationMap(
  formLabels: Record<CanonicalFormLabels, string>,
  formNotes: Record<CanonicalFormNotes, string>,
  tableHeaders: Record<CanonicalTableHeaders, string>,
  enumTranslations: Record<CanonicalEnumTranslations, string>,
) {
  return {
    ...addKeyPrefix('labels', formLabels),
    ...addKeyPrefix('notes', formNotes),
    ...addKeyPrefix('tableHeaders', tableHeaders),
    ...enumTranslations,
  }
}

export type TranslationMap = ReturnType<typeof BuildTranslationMap>
