import { type FormGroupDefinition } from './formGroupDefinition'
import { z } from 'zod'

import { Akte, AkteSchema } from '@dpa/common/dist'

import { SchemaToFormSchema } from '../components/form/Autoform'
// import { NoExtraProperties, NonPartial } from '../utils/typing'
// import { OnlyValideLabels } from '../locales/translationKeys'

export const Datenblatt_OmittedFields = {
  id: true,
  creationData: true,
} satisfies { [key in keyof Akte]?: true }

const Datenblatt_Schema = AkteSchema.omit(Datenblatt_OmittedFields)

export const DatenblattFields = SchemaToFormSchema(Datenblatt_Schema, {})

// type _DatenblattFieldsType = NonPartial<z.infer<typeof DatenblattFields>>
//
// type _HasLabels_DatenblattFields = NoExtraProperties<
//   _DatenblattFieldsType,
//   Pick<_DatenblattFieldsType, OnlyValideLabels<keyof _DatenblattFieldsType>>
// >

export const datenblattFromSchema: FormGroupDefinition<
  | 'Projektdaten'
  | 'Kundendaten'
  | 'Engineering Dokumente'
  | 'Umfang der Angebotsstellung'
  | 'Anschlussinformationen_a'
  | 'Anschlussinformationen_AC-Ladestationen'
  | 'Anschlussinformationen_DC-Ladestationen'
  | 'Anschlussinformationen_b'
  | 'Dienstleistungen'
  | 'Backend'
  | 'Backend_Sonstige'
  | 'Backend_Marketinginformationen'
  | 'Backend_Identifikationsmedien'
  | 'Backend_Ident'
  | 'Backend_THGQuote'
  | 'Netzwerkinformationen'
  | 'Wartung'
  | 'EnergieUndLademanagementkonzept_Lademanagement'
  | 'EnergieUndLademanagementkonzept_Anforderung'
  | 'EnergieUndLademanagementkonzept_BeschreibungLademanagementkonzept',
  keyof z.infer<typeof DatenblattFields>
> = {
  Projektdaten: {
    fields: [
      'PDB_Projekttyp',
      'PDB_Projektart',
      'PDB_Projektnummer',
      'UE_Kundendaten_Projekttitel',
      'PDB_WasVerkauft',
      'PDB_LieferterminVerbindlich',
      'PDB_LieferterminGewuenscht',
      'PDB_VertriebsmitarbeiterId',
      'PDB_InnendienstmitarbeiterId',
      'PDB_ProjektiererId',
      'PDB_Projektleiter_PMOId',
      'PDB_Ansprechpartner_Projektsupport',
      'PDB_Ansprechpartner_TBFId',
      'PDB_Auftragsnummer',
      'PDB_Auftragsnummer_PC',
      'PDB_Auftragsnummer_Nachtrag0',
      'PDB_Auftragsnummer_Nachtrag1',
      'PDB_Auftragsnummer_Nachtrag2',
      'PDB_Auftragsnummer_Nachtrag3',
      'PDB_Auftragsnummer_Nachtrag4',
      'PDB_Auftragsnummer_laufende_Kosten',
    ],
  },
  Kundendaten: {
    fields: [
      'BC_Kunde_Name',
      'BC_Kunde_EMail',
      'BC_Kunde_Telefon',
      'BC_Kunde_Adresse',
      'BC_Kunde_PLZ',
      'BC_Kunde_Stadt',
      'BC_Rechnung_EMail',
      'BC_UStIdNr_Kunde',
      'UE_Kundendaten_Ansprechpartner_Name',
      'UE_Kundendaten_Ansprechpartner_EMail',
      'UE_Kundendaten_Ansprechpartner_Telefon',
      'UE_Kundendaten_LIS_Standort_abw_rechnung',
      'UE_Kundendaten_LIS_Standort_Strasse',
      'UE_Kundendaten_LIS_Standort_PLZ',
      'UE_Kundendaten_LIS_Standort_Stadt',
      'BC_Rechnungsanschrift_Name',
      'BC_Rechnungsanschrift_Strasse',
      'BC_Rechnungsanschrift_PLZ',
      'BC_Rechnungsanschrift_Stadt',
      'BC_Rechnungsanschrift_Land',
      'BC_Lieferanschrift_Name',
      'BC_Lieferanschrift_Strasse',
      'BC_Lieferanschrift_PLZ',
      'BC_Lieferanschrift_Stadt',
      'BC_Lieferanschrift_Land',
      'Avisierung_Kontakt',
      'Avisierung_Telefon',
      'Avisierung_Email',
      'BC_LieferanschriftVerified',
      'PDB_LIS_Inhaber_abweichend_rechnungsanschrift',
      'PDB_LIS_Inhaber',
      'PDB_LIS_Betreiber_abweichend_connect',
      'PDB_LIS_Betreiber',
      'PDB_Grundstueckseigentuemer_abweichend_LIS',
      'PDB_Grundstueckseigentuemer_Name',
      'PDB_Grundstueckseigentuemer_EMail',
      'PDB_Grundstueckseigentuemer_Telefon',
      'PDB_Grundstueckseigentuemer_Adresse',
      "PDB_Wartung_Gestattungsvertrag",
      'PDB_Anschlussnutzer_abweichend_LIS',
      'PDB_Anschlussnutzer_Name',
      'PDB_Anschlussnutzer_EMail',
      'PDB_Anschlussnutzer_Telefon',
      'PDB_Anschlussnutzer_Adresse',
      'PDB_Zahlungskonditionen',
      'PDB_Anzahlungskonditionen',
      'PDB_Anzahlungsrechnung_verschickt',
    ],
  },
  'Engineering Dokumente': {
    fields: [
      'PDB_Vollmacht_Kunde',
      'UE_Dokumente_UebersichtsplanMitSkizzierterLIS',
      'UE_Dokumente_FotodokumentationGemVorgabe',
      'UE_Dokumente_Vollmacht',
      'UE_Dokumente_Lageplan',
      'UE_Dokumente_Leitungsplaene',
      'UE_Dokumente_Blitzschutzkonzept',
      'UE_Dokumente_GrundrissMitTechnikraum',
      'UE_Dokumente_Schnitte',
      'UE_Dokumente_Brandschutzkonzept',
      'UE_Dokumente_PlaeneAndererGewerke',
      'UE_Dokumente_Kampfmittelfreiheit',
    ],
  },
  'Umfang der Angebotsstellung': {
    // TODO:
    fields: ['UE_Umfang_PC_AbstimmungNetzbetreiber', 'UE_Umfang_PC_Trafoplanung'],
  },
  Anschlussinformationen_a: {
    fields: [
      'PDB_Ladestationen_Instalateur',
      'UE_Zusatzinfos_AnschulssTyp',
      'PDB_Netzanschluss',
      'PDB_Netzform',
      'PDB_LISNutzbareLeistung',
      'PDB_Kundenanschluss',
      'PDB_SeperateEVUZahlung',
      'PDB_LSProdArtNr',
      'PDB_LSSerialNr',
      'PDB_Eichrechtskonform',
      'PDB_LadepunkteTyp',
      'PDB_IimLademanagement_InternesLademanagement',
      'PDB_Versanddatum',
      'PDB_UdpAktive',
      'PDB_RFIDKartennummer',
      'PDB_ZusaetzlicheAnschlussinformationen',
      'PDB_AusbauGewuenscht',
      'PDB_GeplanteFertisgtellung',
      'PDB_GeplanteInstalation',
    ],
  },
  'Anschlussinformationen_AC-Ladestationen': {
    fields: [
      'UE_Ladestationen_TypLadestationAC',
      'UE_Ladestationen_AnzahlLadepunkteAC',
      'UE_Ladestationen_LadeleistungJeLadepunktAC',
      'UE_Ladestationen_AnzahlLadestationenAC',
      'UE_Ladestationen_AnzahlStellplaetzeAC',
    ],
  },
  'Anschlussinformationen_DC-Ladestationen': {
    fields: [
      'UE_Ladestationen_TypLadestationDC',
      'UE_Ladestationen_AnzahlLadepunkteDC',
      'UE_Ladestationen_LadeleistungJeLadepunktDC',
      'UE_Ladestationen_AnzahlLadestationenDC',
      'UE_Ladestationen_AnzahlStellplaetzeDC',
    ],
  },
  Anschlussinformationen_b: {
    fields: [
      'UE_Ladestationen_GesamtleistungMoreThen11kW',
      'UE_Ladestationen_Gesamtleistung',
      'PDB_FirmenlogoAufDisplay',
      'PDB_FirmenlogoLink',
      'PDB_AnmeldungBeiNetzbetreiber',
      'PDB_GenehmigungNetzbetreiber',
      'PDB_DatumGenehmigung_Anmeldung',
    ],
  },
  Dienstleistungen: {
    fields: [
      'AnmeldungBNetzAErforderlich',
      'AnmeldungBNetzAErfolgt',
      'Foerderung_vorhanden',
      'Foerderung',
      'FoerderungEnde',
      'FoerderungClearing',
    ],
  },
  Backend: {
    fields: [
      'PDB_Backend_Servicevertrag',
      'PDB_Backend_ServicevertragVersandt',
      'PDB_Backend_OtrisLink',
      'PDB_Backend_DauerrechnungGemeldet',
      'PDB_Backend_ZugangZumLPMSErwuenscht',
      'PDB_Backend_Backend_UserAz',
      'PDB_Backend_NameUndEMail',
      'PDB_Backend_LadepunkteOeffentlich',
      'PDB_Backend_Ladestationsname_Fremd',
      'PDB_Backend_Type',
      'PDB_Backend_VerkaufskostensatzAC',
      'PDB_Backend_VerkaufskostensatzDC',
      'PDB_Backend_Authentifizierung',
      'PDB_Backend_AnzeigeInApp',
      'PDB_Backend_Roaming_Vermarkten',
      'PDB_Backend_Direct',
      'PDB_Backend_Kartenterminal',
      'PDB_Backend_Vorautorisierungsbetrag',
      'PDB_Backend_Zeittarif_AC',
      'PDB_Backend_Zeittarif_DC',
      'PDB_Backend_Waehrung',
      'PDB_Backend_Steuersatz',
      'PDB_Backend_SteuernummerDesKunden',
      'PDB_Backend_FirmennameDesKunden',
      'PDB_Backend_Firmenanschrift',
      'PDB_Backend_EODTime',
      'PDB_Backend_PCISO15118',
      'PDB_Backend_Geolokation_long',
      'PDB_Backend_Geolokation_lat',
    ],
  },
  Backend_Sonstige: {
    fields: [
      'PDB_Backend_Ladestationsnamen',
      'PDB_Backend_KundenBackend',
      'PDB_Backend_ZugangsdatenKundenBackend',
      'PDB_Backend_EVSEID_QRCode',
      'PDB_Backend_Roaming',
      'DC_Standort_Name',
      'AC_Standort_Name',
    ],
  },
  Backend_Marketinginformationen: {
    fields: [
      'PDB_Backend_HotlineTyp',
      'PDB_Backend_HotlineRufnummerConnect',
      'PDB_Backend_HotlineRufnummerKunde',
      'PDB_Backend_AnzeigeInPortalen',
      'PDB_Backend_OeffnungszeitenUndZugang',
      'PDB_Backend_ExterneKartendienste',
    ],
  },
  Backend_THGQuote: {
    fields: ['PDB_Backend_THGQuote'],
  },
  Backend_Identifikationsmedien: {
    fields: [
      'PDB_Backend_Ident_zustaendigerAP',
      'PDB_Backend_Ident_rueckerstattungKonto',
      'PDB_Backend_Ident_rueckerstattungGemeldet',
      'PDB_Backend_Ident_rueckerstattungBemerkung',
    ],
  },
  Backend_Ident: {
    fields: [
      'ident_dienstwagen',
      'ident_dienstwagen_wo',
      'ident_dienstwagen_wie_dienstwagen',
      'ident_dienstwagen_wie_mieter',
      'ident_dienstwagen_EinsZuEins',
      'ident_dienstwagen_EinsZuEins_Beziehung',
      'ident_dienstwagen_zusatz',
      'ident_dienstwagen_rechnungsempfaenger',
      'ident_dienstwagen_rechnungsempfaenger_name',
      'ident_dienstwagen_rechnungsempfaenger_adresse',
      'ident_dienstwagen_rechnungsempfaenger_plz',
      'ident_dienstwagen_rechnungsempfaenger_stadt',
      'ident_dienstwagen_rechnungsempfaenger_email',
      'ident_dienstwagen_AP_name',
      'ident_dienstwagen_AP_email',
      'ident_dienstwagen_ladekartenBestellung',
      'ident_dienstwagen_sondertarief',

      'ident_privat',
      'ident_privat_wo',
      'ident_privat_wie',
      'ident_privat_EinsZuEins',
      'ident_privat_EinsZuEins_Beziehung',
      'ident_privat_zusatz',
      'ident_privat_rechnungsempfaenger',
      'ident_privat_AP_name',
      'ident_privat_AP_email',
      'ident_privat_ladekartenBestellung',
      'ident_privat_sondertarief',
        
      'ident_mieter',                      
      'ident_mieter_wie',                  
      'ident_mieter_EinsZuEins',           
      'ident_mieter_EinsZuEins_Beziehung', 
      'ident_mieter_zusatz',               
      'ident_mieter_rechnungsempfaenger',  
      'ident_mieter_AP_name',              
      'ident_mieter_AP_email',             
      'ident_mieter_ladekartenBestellung', 
      'ident_mieter_sondertarief',
      'ident_vouchercodes',         
        ],
  },

  Netzwerkinformationen: {
    fields: [
      'PDB_Backend_Verbindungsmethode',
      'PDB_Backend_Routertyp',
      'PDB_Backend_NetzwerkHost',
      'PDB_Backend_Internetanschluss',
      'PDB_Backend_LucomRouter',
      'PDB_Backend_KonfigurationLucomRouter',
      'PDB_Backend_LucomConnectRouter',
      'PDB_Backend_ZusaetzlicheInformationen',
    ],
  },
  Wartung: {
    fields: [
      'PDB_Wartung_AnsprechpersonAbweichend',
      'PDB_Wartung_Ansprechperson',
      'PDB_Wartung_AnsprechpersonTelefon',
      'PDB_Wartung_AnsprechpersonEMail',
      'PDB_Wartung_IBNProtokoll',
      'PDB_Wartung_ProtokollSchluesseluebergabe',
      'PDB_Wartung_SchliesszylinderUVausgetauscht',
      'PDB_Wartung_AnsprechpersonSchluessel',
      'PDB_Wartung_VollstaendigeDokumentation',
      'PDB_Wartung_Gewaehrleistungsart',
      'PDB_Wartung_GewaehrleistungsartSonstige',
      'PDB_Wartung_AbnahmeProtManegelfrei',
      'PDB_Wartung_StromlaufplaeneVorhanden',
      'PDB_Wartung_Gewaehrleistung_Beginn',
      'PDB_Wartung_Gewaehrleistung_Laufzeit',
      'PDB_Wartung_Herstellergarantie',
      'PDB_Wartung_Herstellergarantie_Laufzeit',
      'PDB_Wartung_AnlageStoerungsfrei',
      'PDB_Wartung_TestladevorgangErfolg',
      'PDB_Wartung_ProjektbeschreibungVorhanden',
      'PDB_Wartung_FotoDokuVorhanden',
    ],
  },
  EnergieUndLademanagementkonzept_Lademanagement: {
    fields: [
      'PDB_EundL_Lademanagement_Status',
      'PDB_EundL_Lademanagement_Smart1Controller',
      'PDB_EundL_Lademanagement_DokumentationSmart1Vorhanden',
      'PDB_EundL_Lademanagement_TMHChargepilot',
      'PDB_EundL_Lademanagement_DokumentationTMHVorhanden',
      'PDB_EundL_Lademanagement_InternesLademanagement',
      'PDB_EundL_Lademanagement_SeriennummerLLMController',
      'PDB_EundL_Lademanagement_ZugangZumLastmanagement',
      'PDB_EundL_Lademanagement_IBNProtokollLLEMSvorhanden',
    ],
  },
  EnergieUndLademanagementkonzept_Anforderung: {
    fields: [
      'PDB_EundL_Anforderung_StatischesLademanagement',
      'PDB_EundL_Anforderung_DocuStatischesMgmntVorhanden',
      'PDB_EundL_Anforderung_DynamischesLademanagement',
      'PDB_EundL_Anforderung_DocuDynamischesMgmntVorhanden',
      'PDB_EundL_Anforderung_Schieflastbegrenzung',
      'PDB_EundL_Anforderung_DocuSchieflastbegrenzungVorhanden',
      'PDB_EundL_Anforderung_PVIntegration',
      'PDB_EundL_Anforderung_DocuPVIntegrationVorhanden',
      'PDB_EundL_Anforderung_BatterieIntegration',
      'PDB_EundL_Anforderung_DocuBatterieIntegrationVorhanden',
      'PDB_EundL_Anforderung_Nutzerpriorisierung',
      'PDB_EundL_Anforderung_DocuNutzerpriorisierungVorhanden',
      'PDB_EundL_Anforderung_Ladepunktpriorisierung',
      'PDB_EundL_Anforderung_DocuLadepunktpriorisierungVorhanden',
    ],
  },
  EnergieUndLademanagementkonzept_BeschreibungLademanagementkonzept: {
    fields: ['PDB_BeschreibungLademanagementkonzept'],
  },
}
