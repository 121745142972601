import { useEffect, useMemo, useState, PropsWithChildren } from 'react'

import { View } from 'react-native'
import { dsv } from '../../styles/defaults'
import { Text } from '../TextComponents'
import { useTranslation } from 'react-i18next'
import { canonicalFormNotes, CanonicalFormNotes, addPrefix, canonicalFormLabels } from '../../locales/translationKeys'

import {
  z,
  ZodRawShape,
  ZodDate,
  ZodNumber,
  ZodString,
  ZodTypeAny,
  ZodOptional,
  ZodBoolean,
  ZodEnum,
  ZodNullable,
} from 'zod'

import type { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

import { InputBoolean } from './AutoFormInputFields/InputBoolean'
import { InputDate } from './AutoFormInputFields/InputDate'
import { InputNumber } from './AutoFormInputFields/InputNumber'
import { InputText } from './AutoFormInputFields/InputText'
import { InputEnum } from './AutoFormInputFields/InputEnum'
import { UserDropdownSelection } from './AutoFormInputFields/UserDropdownSelection'

export function calculateTranslationKeys<TLabel extends string>(fieldKey: TLabel) {
  return {
    labelTranslationKey: addPrefix('labels', fieldKey),
    notesTranslationKey:
      (canonicalFormNotes as string[]).indexOf(fieldKey) != -1
        ? addPrefix('notes', fieldKey as CanonicalFormNotes)
        : undefined,
  }
}

////////////////////////
/*
export const FormData = z.object({
  someText:     z.string().min(5),
  aNumber:      z.coerce.number().min(3).max(10),  // TODO check type coerce for ""
  optionalText: z.string().optional(),  // TODO check type coerce for ""
  aDate:        z.coerce.date()
})
*/

import { memo } from 'react'
import { Partial } from '../../utils/typing'
import { mapObj } from '@dpa/common/dist'

function useFormState<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  shapeEntries: [keyof T, z.ZodTypeAny][],
  writableFields: { [key in keyof T]?: boolean }
) {
  const [stringState, setStringState] = useState<{ [fieldName in keyof T]?: string }>({})

  const setStringStateFunctionsEntries = useMemo(() => {
    if (writableFields == undefined) {
      return undefined
    }

    return shapeEntries.map(([name, _]) => {
      if (writableFields[name] === true) {
        return [
          name,
          (fieldValue: string) => {
            setStringState((state) => ({
              ...state,
              [name]: fieldValue,
            }))
          },
        ] as [keyof T, (fieldValue: unknown) => void]
      }
      return [name, undefined] as [keyof T, undefined]
    })
  }, [shapeEntries, writableFields])

  return {
    stringState,
    setStringStateFunctionsEntries,
    setStringState,
  }
}

const fieldInputOverwrites: { [name: string]: undefined | ['userDropdown', RoleEnumType] } = {
  PDB_VertriebsmitarbeiterId: ['userDropdown', 'Vertrieb'],
  PDB_InnendienstmitarbeiterId: ['userDropdown', 'Innendienst'],
  PDB_ProjektiererId: ['userDropdown', 'Projektierung'],
  PDB_Projektleiter_PMOId: ['userDropdown', 'PMO'],
  PDB_Ansprechpartner_ProjektsupportId: ['userDropdown', 'ServiceUndWartung'],
}
interface FormFieldInputProps {
  fieldSchema: z.ZodTypeAny
  fieldId: string
  fieldName: string
  fieldPath?: string
  strValue: string
  setStrValue?: (value: string) => void
  saveStrValue?: (value?: string) => void
  ignoreOverwrite?: boolean
}

function FormFieldInput({
  fieldSchema,
  fieldId,
  fieldName,
  //TODO: fieldPath,
  strValue,
  setStrValue,
  saveStrValue,
  ignoreOverwrite,
}: FormFieldInputProps) {
  while (fieldSchema instanceof ZodNullable || fieldSchema instanceof ZodOptional) {
    fieldSchema = fieldSchema.unwrap()
  }
  const inputOverwrite = ignoreOverwrite === true ? undefined : fieldInputOverwrites[fieldName]
  if (inputOverwrite !== undefined) {
    const [type, params] = inputOverwrite
    if (type == 'userDropdown') {
      return (
        <UserDropdownSelection
          fieldId={fieldId}
          strValue={strValue}
          setStrValue={setStrValue}
          saveStrValue={saveStrValue}
          role={params}
        />
      )
    } else {
      throw new Error('unsupported Field Input overwrite')
    }
  } else if (fieldSchema instanceof ZodString) {
    return <InputText fieldId={fieldId} strValue={strValue} setStrValue={setStrValue} saveStrValue={saveStrValue} />
  } else if (fieldSchema instanceof ZodNumber) {
    return <InputNumber fieldId={fieldId} strValue={strValue} setStrValue={setStrValue} saveStrValue={saveStrValue} />
  } else if (fieldSchema instanceof ZodDate) {
    return <InputDate fieldId={fieldId} strValue={strValue} setStrValue={setStrValue} saveStrValue={saveStrValue} />
  } else if (fieldSchema instanceof ZodBoolean) {
    return <InputBoolean fieldId={fieldId} strValue={strValue} setStrValue={setStrValue} saveStrValue={saveStrValue} />
  } else if (fieldSchema instanceof ZodEnum) {
    const options = ['', ...Object.values(fieldSchema.Enum)]
    return (
      <InputEnum
        fieldId={fieldId}
        strValue={strValue}
        setStrValue={setStrValue}
        saveStrValue={saveStrValue}
        options={options}
      />
    )
  }

  throw new Error('unsupported Field Input Type')
}

const FormFieldInputMemo = memo(FormFieldInput)

function fieldCanMemo<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  shapeEntries: [keyof T, z.ZodTypeAny][],
  ignoreOverwrite?: boolean
) {
  return shapeEntries.map(([fieldName, fieldSchema]) => {
    while (fieldSchema instanceof ZodNullable || fieldSchema instanceof ZodOptional) {
      fieldSchema = fieldSchema.unwrap()
    }

    const inputOverwrite = ignoreOverwrite === true ? undefined : fieldInputOverwrites[fieldName as string]
    if (inputOverwrite !== undefined) {
      const [type, _] = inputOverwrite
      if (type == 'userDropdown') {
        return false
      } else {
        throw new Error('unsupported Field Input overwrite')
      }
    } else if (
      fieldSchema instanceof ZodString ||
      fieldSchema instanceof ZodNumber ||
      fieldSchema instanceof ZodBoolean ||
      fieldSchema instanceof ZodEnum
    ) {
      return true
    } else if (fieldSchema instanceof ZodDate) {
      return false
    }

    throw new Error('unsupported Field Input Type')
  })
}

interface FormFieldLabelProps {
  fieldId: string
  fieldName: string
  setStrValue?: (value: string) => void
  isMandatory?: boolean
}

function FormFieldLabel({
  fieldId,
  fieldName,
  setStrValue,
  isMandatory,
  children,
}: PropsWithChildren<FormFieldLabelProps>) {
  const { t } = useTranslation()
  const { labelTransKey, noteTransKey } = useMemo(() => {
    return {
      labelTransKey: (canonicalFormLabels as Array<string>).includes(fieldName)
        ? addPrefix('labels', fieldName)
        : undefined,
      noteTransKey: (canonicalFormNotes as Array<string>).includes(fieldName)
        ? addPrefix('notes', fieldName)
        : undefined,
    }
  }, [fieldName])

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 12,
          alignItems: 'center',
        }}
      >
        <label
          htmlFor={fieldId}
          style={{
            color: dsv.colors.neutral,
            marginRight: '2rem',
            width: '30%',
            ...dsv.text,
          }}
        >
          <Text>
            {labelTransKey ? t(labelTransKey) : `no label for ${fieldName}`}
            {isMandatory && (
              // TODO: Fix styling for mobile app
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Text style={{ color: 'red', marginLeft: '.5em' }}>*</Text>
            )}
            {
              <>
                {' '}
                <br />
                {noteTransKey ? t(noteTransKey) : ''}
              </>
            }
          </Text>
        </label>
        <View
          style={{
            width: '70%',
            backgroundColor: setStrValue == undefined ? dsv.colors.neutral2 : undefined,
          }}
        >
          {children}
        </View>
      </View>
    </>
  )
}
const FormFieldLabelMemo = memo(FormFieldLabel)

function ZodFormValueToString<T extends ZodTypeAny>(fieldSchema: T, value: z.infer<T> | undefined) {
  if (fieldSchema instanceof ZodOptional) {
    return ZodFormValueToString(fieldSchema.unwrap(), value)
  }
  if (fieldSchema instanceof ZodNullable) {
    return ZodFormValueToString(fieldSchema.unwrap(), value)
  }

  if (value == undefined) return ''

  // TODO: bool2string support
  if (fieldSchema instanceof ZodBoolean) {
    return `${value}`
  }

  if (fieldSchema instanceof ZodString) {
    return value
  }
  if (fieldSchema instanceof ZodNumber) {
    return String(value)
  }
  if (fieldSchema instanceof ZodDate) {
    return value.toISOString().substring(0, 10)
  }
  if (fieldSchema instanceof ZodEnum) {
    return value // TODO: validate
  }
  console.log('unsupported fieldSchema:', fieldSchema)
  throw new Error('ZodFormValueToString: unsupported Field Input Type')
}

export function useNewAutoForm<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  writableFields: { [field in keyof T]?: boolean },
  saveStrValueFunction: (fieldName: string, value: string) => void,
  serverState: Partial<z.infer<ReturnType<typeof z.object<T>>>> | undefined,
  ignoreOverwrite?: boolean
) {
  const uuid = useMemo(() => crypto.randomUUID(), [])

  const shapeEntries = useMemo(() => Object.entries(schema.shape) as [keyof T, z.ZodTypeAny][], [schema.shape])
  const [hasData, setHasData] = useState(false)

  const formState = useFormState(schema, shapeEntries, writableFields) // TODO make more ore less dynamic
  const setStrStateFunctions = useMemo(
    () =>
      formState.setStringStateFunctionsEntries == undefined
        ? undefined
        : Object.fromEntries(formState.setStringStateFunctionsEntries),
    [formState.setStringStateFunctionsEntries]
  )

  useEffect(() => {
    if (serverState == undefined) {
      return
    }
    const res = mapObj(serverState, (value, fieldName) => {
      const fieldShape = schema.shape[fieldName]
      if (fieldShape == undefined) {
        return undefined
      }
      return ZodFormValueToString(fieldShape, value)
    })
    setHasData(true)
    formState.setStringState((oldStringState) => ({
      //...oldStringState, // TODO: Might need to be included, without oldStringState things that are not in res will be overwritten (which is needed for now)
      ...res,
    }))
  }, [serverState, formState.setStringState, schema.shape])

  const saveStrStateFunctionsWithoutState = useMemo(() => {
    return formState.setStringStateFunctionsEntries == undefined
      ? undefined
      : formState.setStringStateFunctionsEntries.map(([name, setStrStateFnc]) => {
        if (setStrStateFnc == undefined) {
          return [name, undefined] as [string, undefined]
        }
        if (saveStrValueFunction == undefined) {
          return [name, undefined] as [string, undefined]
        }
        return [
          name,
          (value?: string, stateValue?: string) => {
            if (value !== undefined) {
              setStrStateFnc(value)
              saveStrValueFunction(name as string, value)
            } else {
              saveStrValueFunction(name as string, stateValue ?? '')
            }
          },
        ] as [string, (value?: string | undefined, stateValue?: string | undefined) => void]
      })
  }, [formState.setStringStateFunctionsEntries, saveStrValueFunction])

  const [saveStrStateFunctions, setSaveStrStateFunctions] = useState<{
    [fieldName in keyof T]?: (value?: string | undefined) => void
      }>({})

  useEffect(() => {
    const saveStrStateFncs = saveStrStateFunctionsWithoutState?.map(([fieldName, saveStrStateFnc]) => {
      // TODO: Only set on Object Delta
      if (saveStrStateFnc) {
        return [
          fieldName,
          (value?: string) => {
            saveStrStateFnc(value, formState.stringState[fieldName])
          },
        ]
      } else {
        return [fieldName, undefined]
      }
    })
    if (saveStrStateFncs) {
      setSaveStrStateFunctions(Object.fromEntries(saveStrStateFncs))
    }
  }, [formState.stringState, saveStrStateFunctionsWithoutState])

  const canMemo = useMemo(
    () => fieldCanMemo(schema, shapeEntries, ignoreOverwrite),
    [schema, shapeEntries, ignoreOverwrite]
  )

  const inputFieldEntries = shapeEntries.map(([name, fieldType], idx) => {
    return [
      name,
      canMemo[idx] ? (
        <FormFieldInputMemo
          key={name as string}
          fieldId={`${uuid}_${name as string}`}
          fieldName={name as string}
          fieldSchema={fieldType}
          strValue={formState.stringState[name] ?? ''}
          setStrValue={setStrStateFunctions == undefined ? undefined : setStrStateFunctions[name]}
          saveStrValue={saveStrStateFunctions[name]}
          ignoreOverwrite={ignoreOverwrite}
        />
      ) : (
        <FormFieldInput
          key={name as string}
          fieldId={`${uuid}_${name as string}`}
          fieldName={name as string}
          fieldSchema={fieldType}
          strValue={formState.stringState[name] ?? ''}
          setStrValue={setStrStateFunctions == undefined ? undefined : setStrStateFunctions[name]}
          saveStrValue={saveStrStateFunctions[name]}
          ignoreOverwrite={ignoreOverwrite}
        />
      ),
    ] as [keyof T, JSX.Element]
  })
  const formEntries = inputFieldEntries.map(([name, inputField], idx) => {
    return [
      name,
      canMemo[idx] ? (
        <FormFieldLabelMemo
          key={name as string}
          fieldId={`${uuid}_${name as string}`}
          fieldName={name as string}
          setStrValue={setStrStateFunctions == undefined ? undefined : setStrStateFunctions[name]}
        >
          {inputField}
        </FormFieldLabelMemo>
      ) : (
        <FormFieldLabel
          key={name as string}
          fieldId={`${uuid}_${name as string}`}
          fieldName={name as string}
          setStrValue={setStrStateFunctions == undefined ? undefined : setStrStateFunctions[name]}
        >
          {inputField}
        </FormFieldLabel>
      ),
    ] as [keyof T, JSX.Element]
  })
  return { inputFieldEntries, formEntries, hasData }
}

/**
 * DO NOT USE THIS FUNCTION OUT SIDE OF coerceIfNeeded
 */
function _coerceIfNeeded<T extends ZodTypeAny>(fieldSchema: T): T {
  if (fieldSchema instanceof ZodBoolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.coerce.boolean() as any as T
  }
  if (fieldSchema instanceof ZodNumber) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.coerce.number() as any as T
    // TODO: do not reset sting value for numbers if text in string
  }
  if (fieldSchema instanceof ZodString) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.string().nonempty() as any as T // TODO: make nice
  }
  return fieldSchema as T
}

function coerceIfNeeded<T extends ZodTypeAny>(
  fieldSchema: T
): T extends ZodNullable<ZodOptional<infer X>>
  ? ReturnType<typeof _coerceIfNeeded<X>>
  : ReturnType<typeof _coerceIfNeeded<T>> {
  if (fieldSchema instanceof ZodOptional) {
    const inner = fieldSchema.unwrap()
    if (inner instanceof ZodNullable) {
      return _coerceIfNeeded(inner.unwrap()).nullish() as T extends ZodNullable<ZodOptional<infer X>>
        ? ReturnType<typeof _coerceIfNeeded<X>>
        : ReturnType<typeof _coerceIfNeeded<T>>
    }
  }
  return _coerceIfNeeded(fieldSchema) as T extends ZodNullable<ZodOptional<infer X>>
    ? ReturnType<typeof _coerceIfNeeded<X>>
    : ReturnType<typeof _coerceIfNeeded<T>>
}

export function SchemaToFormSchema<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  modifier?: Partial<{ [field in keyof T]: ZodTypeAny }>
) {
  const coercedEntries = Object.entries(schema['shape']).map(([name, typ]) => {
    return [name, coerceIfNeeded(typ)]
  })
  const coercedObj = Object.fromEntries(coercedEntries) as {
    [field in keyof (typeof schema)['shape']]: ReturnType<typeof coerceIfNeeded<(typeof schema)['shape'][field]>>
  }

  const form = z.object({
    ...coercedObj,
    ...modifier,
  })
  return form
}
