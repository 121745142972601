import { View } from 'react-native'

import { H3, Text } from '../../../../components/TextComponents'

import { RejectionDialog } from './RejectionDialog'

import { useCallback, useState, useMemo } from 'react'
import { dsv } from '../../../../styles/defaults'
import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useUpdateAkteMutation } from '../../../../hooks/update/mutations/useUpdateAkteMutation'
import { useAuthContext } from '../../../../hooks/context/useAuthContext'
import { GpjButton } from './GpjButton'
import { useTranslation } from 'react-i18next'

interface UebergabeHeaderProps {
  uebergabeStep: 'Projektierung' | 'Vertrieb' | 'PMO' | 'S&W'
  uebergabeBlocked: boolean
}

export function UebergabeHeader({ uebergabeStep, uebergabeBlocked }: UebergabeHeaderProps) {
  const { t } = useTranslation()
  const akte = useAkteContext()
  const updateAkteMutation = useUpdateAkteMutation()

  const { hasRole } = useAuthContext()

  const [rejectionDialogeOpen, setRejectionDialogeOpen] = useState(false)
  const [revocationDialogeOpen, setRevocationDialogeOpen] = useState(false)

  const openRejectionDialoge = useCallback(() => {
    setRevocationDialogeOpen(false)
    setRejectionDialogeOpen(true)
  }, [setRejectionDialogeOpen])

  const openRevocationDialoge = useCallback(() => {
    setRejectionDialogeOpen(false)
    setRevocationDialogeOpen(true)
  }, [setRevocationDialogeOpen])

  const closeDialogs = useCallback(() => {
    setRejectionDialogeOpen(false)
    setRevocationDialogeOpen(false)
  }, [setRevocationDialogeOpen])

  const uebergabeRequestAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: new Date(),
          })
        }
      case 'Vertrieb':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: new Date(),
          })
        }
      case 'PMO':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: new Date(),
          })
        }
      case 'S&W':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: new Date(),
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const anfrageRejectAndCloseDialoge = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return () => {
        closeDialogs()
        updateAkteMutation.mutate({
          ...akte.identifier,
          Process_Uebergabe_Vertrieb_requested: null,
          Process_Uebergabe_Vertrieb_preapproved: null,
        })
      }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const anfrageApproveAndCloseDialoge = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return () => {
        closeDialogs()
        updateAkteMutation.mutate({
          ...akte.identifier,
          Process_Uebergabe_Vertrieb_preapproved: new Date(),
        })
      }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeRejectAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: null,
            Process_Uebergabe_Projektierung_rejected: new Date(),
            Process_Uebergabe_Projektierung_rejectionComment: rejectionText,
            Process_Uebergabe_Projektierung_revoked: null,
            Process_Uebergabe_Projektierung_revocationComment: null,
          })
        }
      case 'Vertrieb':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: null,
            Process_Uebergabe_Vertrieb_rejected: new Date(),
            Process_Uebergabe_Vertrieb_preapproved: null,
            Process_Uebergabe_Vertrieb_rejectionComment: rejectionText,
            Process_Uebergabe_Vertrieb_revoked: null,
            Process_Uebergabe_Vertrieb_revocationComment: null,
          })
        }
      case 'PMO':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: null,
            Process_Uebergabe_PMO_rejected: new Date(),
            Process_Uebergabe_PMO_rejectionComment: rejectionText,
            Process_Uebergabe_PMO_revoked: null,
            Process_Uebergabe_PMO_revocationComment: null,
          })
        }
      case 'S&W':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: null,
            Process_Uebergabe_SW_rejected: new Date(),
            Process_Uebergabe_SW_rejectionComment: rejectionText,
            Process_Uebergabe_SW_revoked: null,
            Process_Uebergabe_SW_revocationComment: null,
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeApproveAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_approved: new Date(),
          })
        }
      case 'Vertrieb':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_approved: new Date(),
          })
        }
      case 'PMO':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_approved: new Date(),
          })
        }
      case 'S&W':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_approved: new Date(),
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeRevokeAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: null,
            Process_Uebergabe_Projektierung_approved: null,
            Process_Uebergabe_Projektierung_rejected: null,
            Process_Uebergabe_Projektierung_rejectionComment: null,
            Process_Uebergabe_Projektierung_revoked: new Date(),
            Process_Uebergabe_Projektierung_revocationComment: revocationText,
          })
        }
      case 'Vertrieb':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: null,
            Process_Uebergabe_Vertrieb_approved: null,
            Process_Uebergabe_Vertrieb_rejected: null,
            Process_Uebergabe_Vertrieb_rejectionComment: null,
            Process_Uebergabe_Vertrieb_preapproved: null,
            Process_Uebergabe_Vertrieb_revoked: new Date(),
            Process_Uebergabe_Vertrieb_revocationComment: revocationText,
          })
        }
      case 'PMO':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: null,
            Process_Uebergabe_PMO_approved: null,
            Process_Uebergabe_PMO_rejected: null,
            Process_Uebergabe_PMO_rejectionComment: null,
            Process_Uebergabe_PMO_revoked: new Date(),
            Process_Uebergabe_PMO_revocationComment: revocationText,
          })
        }
      case 'S&W':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: null,
            Process_Uebergabe_SW_approved: null,
            Process_Uebergabe_SW_rejected: null,
            Process_Uebergabe_SW_rejectionComment: null,
            Process_Uebergabe_SW_revoked: new Date(),
            Process_Uebergabe_SW_revocationComment: revocationText,
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const correctStateForSubmit = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return akte.serverState?.projectStatus === 'In_der_Erfassung'
      case 'Vertrieb':
        return akte.serverState?.Process_Uebergabe_Projektierung_approved != null
      case 'PMO':
        return akte.serverState?.Process_Uebergabe_Vertrieb_approved != null
      case 'S&W':
        return akte.serverState?.Process_Uebergabe_PMO_approved != null
    }
  }, [
    uebergabeStep,
    akte.serverState?.projectStatus,
    akte.serverState?.Process_Uebergabe_Projektierung_approved,
    akte.serverState?.Process_Uebergabe_Vertrieb_approved,
    akte.serverState?.Process_Uebergabe_PMO_approved,
  ])

  const correctRightsForSubmit = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return hasRole('Vertrieb') || hasRole('Innendienst') || hasRole('Admin')
      case 'Vertrieb':
        return (
          hasRole('Projektierung') ||
          hasRole('Admin') ||
          ((akte.serverState?.PDB_Projektart == 'AuslieferungInklKonfiguration' ||
            akte.serverState?.PDB_Projektart == 'DCinklIBN') &&
            (hasRole('Vertrieb') || hasRole('Innendienst')))
        )
      case 'PMO':
        return hasRole('Vertrieb') || hasRole('Innendienst') || hasRole('Admin')
      case 'S&W':
        return hasRole('PMO') || hasRole('Admin')
    }
  }, [uebergabeStep, akte.serverState?.PDB_Projektart, hasRole])

  const submittable = !uebergabeBlocked && correctStateForSubmit && correctRightsForSubmit

  const correctStateForPreAccept = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return akte.serverState?.Process_Uebergabe_Vertrieb_requested != null
    }
  }, [akte.serverState?.Process_Uebergabe_Vertrieb_requested, uebergabeStep])

  const correctRightsForPreAccept = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return (
        (hasRole('Projektierung') && hasRole('Teamleitung')) ||
        hasRole('Admin') ||
        ((akte.serverState?.PDB_Projektart == 'AuslieferungInklKonfiguration' ||
          akte.serverState?.PDB_Projektart == 'DCinklIBN') &&
          (hasRole('Vertrieb') || hasRole('Innendienst')))
      )
    }
  }, [akte.serverState?.PDB_Projektart, hasRole, uebergabeStep])

  const preAcceptable = correctStateForPreAccept && correctRightsForPreAccept

  const correctStateForAccept = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return akte.serverState?.Process_Uebergabe_Projektierung_requested != null
      case 'Vertrieb':
        return akte.serverState?.Process_Uebergabe_Vertrieb_preapproved != null
      case 'PMO':
        return akte.serverState?.Process_Uebergabe_PMO_requested != null
      case 'S&W':
        return akte.serverState?.Process_Uebergabe_SW_requested != null
    }
  }, [
    uebergabeStep,
    akte.serverState?.Process_Uebergabe_Projektierung_requested,
    akte.serverState?.Process_Uebergabe_Vertrieb_preapproved,
    akte.serverState?.Process_Uebergabe_PMO_requested,
    akte.serverState?.Process_Uebergabe_SW_requested,
  ])

  const correctRightsForAccept = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (hasRole('Projektierung') && hasRole('Teamleitung')) || hasRole('Admin')||
        ((akte.serverState?.PDB_Projektart == 'AuslieferungInklKonfiguration' ||
          akte.serverState?.PDB_Projektart == 'DCinklIBN') &&
          (hasRole('Vertrieb') || hasRole('Innendienst')))
      case 'Vertrieb':
        return (
          hasRole('Vertrieb') ||
          hasRole('Admin') ||
          ((akte.serverState?.PDB_Projektart == 'AuslieferungInklKonfiguration' ||
            akte.serverState?.PDB_Projektart == 'DCinklIBN') &&
            (hasRole('Vertrieb') || hasRole('Innendienst')))
        )
      case 'PMO':
        return (hasRole('PMO') && hasRole('Teamleitung')) || hasRole('Admin')
      case 'S&W':
        return hasRole('ServiceUndWartung') || hasRole('Admin')
    }
  }, [uebergabeStep, akte.serverState?.PDB_Projektart, hasRole])
  const acceptable = correctStateForAccept && correctRightsForAccept

  const correctStateForReject = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return akte.serverState?.Process_Uebergabe_Projektierung_requested != null
      case 'Vertrieb':
        return akte.serverState?.Process_Uebergabe_Vertrieb_preapproved != null
      case 'PMO':
        return akte.serverState?.Process_Uebergabe_PMO_requested != null
      case 'S&W':
        return akte.serverState?.Process_Uebergabe_SW_requested != null
    }
  }, [
    uebergabeStep,
    akte.serverState?.Process_Uebergabe_Projektierung_requested,
    akte.serverState?.Process_Uebergabe_Vertrieb_preapproved,
    akte.serverState?.Process_Uebergabe_PMO_requested,
    akte.serverState?.Process_Uebergabe_SW_requested,
  ])

  const correctRightsForReject = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return hasRole('Vertrieb') || hasRole('Innendienst') || hasRole('Admin')
      case 'Vertrieb':
        return (
          hasRole('Projektierung') ||
          hasRole('Admin') ||
          ((akte.serverState?.PDB_Projektart == 'AuslieferungInklKonfiguration' ||
            akte.serverState?.PDB_Projektart == 'DCinklIBN') &&
            (hasRole('Vertrieb') || hasRole('Innendienst')))
        )
      case 'PMO':
        return hasRole('Vertrieb') || hasRole('Innendienst') || hasRole('Admin')
      case 'S&W':
        return hasRole('PMO') || hasRole('Admin')
    }
  }, [uebergabeStep, akte.serverState?.PDB_Projektart, hasRole])
  const rejectable = correctStateForReject && correctRightsForReject

  const revocable = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (
          akte.serverState?.Process_Uebergabe_Vertrieb_approved == null &&
          akte.serverState?.Process_Uebergabe_Vertrieb_requested == null
        )
      case 'Vertrieb':
        return (
          akte.serverState?.Process_Uebergabe_PMO_approved == null &&
          akte.serverState?.Process_Uebergabe_PMO_requested == null
        )
      case 'PMO':
        return (
          akte.serverState?.Process_Uebergabe_SW_approved == null &&
          akte.serverState?.Process_Uebergabe_SW_requested == null
        )
      case 'S&W':
        return true
    }
  }, [
    akte.serverState?.Process_Uebergabe_PMO_approved,
    akte.serverState?.Process_Uebergabe_PMO_requested,
    akte.serverState?.Process_Uebergabe_SW_approved,
    akte.serverState?.Process_Uebergabe_SW_requested,
    akte.serverState?.Process_Uebergabe_Vertrieb_approved,
    akte.serverState?.Process_Uebergabe_Vertrieb_requested,
    uebergabeStep,
  ])

  const uiState:
    | { buttons: 'show_request' | 'show_approve' | 'show_preapprove' }
    | { buttons: 'show_reject'; approvedAt: Date }
    | undefined = useMemo(() => {
      if (uebergabeStep == 'Projektierung') {
        if (akte.serverState?.Process_Uebergabe_Projektierung_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_Projektierung_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_Projektierung_approved }
        }
      } else if (uebergabeStep == 'Vertrieb') {
        if (akte.serverState?.Process_Uebergabe_Vertrieb_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_Vertrieb_preapproved == undefined) {
          return { buttons: 'show_preapprove' }
        } else if (akte.serverState?.Process_Uebergabe_Vertrieb_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_Vertrieb_approved }
        }
      } else if (uebergabeStep == 'PMO') {
        if (akte.serverState?.Process_Uebergabe_PMO_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_PMO_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_PMO_approved }
        }
      } else if (uebergabeStep == 'S&W') {
        if (akte.serverState?.Process_Uebergabe_SW_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_SW_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_SW_approved }
        }
      }
    }, [
      uebergabeStep,
      akte.serverState?.Process_Uebergabe_Projektierung_approved,
      akte.serverState?.Process_Uebergabe_Projektierung_requested,
      akte.serverState?.Process_Uebergabe_Vertrieb_requested,
      akte.serverState?.Process_Uebergabe_Vertrieb_preapproved,
      akte.serverState?.Process_Uebergabe_Vertrieb_approved,
      akte.serverState?.Process_Uebergabe_PMO_approved,
      akte.serverState?.Process_Uebergabe_PMO_requested,
      akte.serverState?.Process_Uebergabe_SW_approved,
      akte.serverState?.Process_Uebergabe_SW_requested,
    ])

  if (uiState == undefined) {
    return <></>
  }
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <H3 style={{
          wordWrap: "break-word",
          color: dsv.colors.connectGrau7,
          marginVertical: 0,
          paddingVertical: 0
        }}>
          Übergabe {t(uebergabeStep)}: {akte.serverState?.UE_Kundendaten_Projekttitel}
        </H3>
        <View style={{ margin: 5 }}>
          {uiState?.buttons == 'show_request' ? (
            uebergabeBlocked 
              ? <Text style={{fontSize:16}} >Fehlende Pflichtfelder</Text> 
              : !correctRightsForSubmit 
                ? <Text style={{fontSize:16}} >Fehlende Rechte für Übergabe</Text> 
                : <>
                  <GpjButton
                    text="Übergabe beantragen"
                    onPress={uebergabeRequestAndCloseDialoge}
                    isDisabled={!submittable}
                  />
                </>
          ) : uiState?.buttons == 'show_preapprove' ? (
            <View style={{ marginRight: 5 }}>
              {preAcceptable ? <>
                <GpjButton
                  text="Anfrage akzeptieren"
                  onPress={anfrageApproveAndCloseDialoge}
                  enabledBackground={dsv.colors.success}
                  isDisabled={!preAcceptable}
                />
                <GpjButton
                  text="Anfrage ablehnen"
                  onPress={anfrageRejectAndCloseDialoge}
                  enabledBackground={dsv.colors.error}
                  isDisabled={!preAcceptable}
                />
              </>: <>
                <Text style={{fontSize:16}}>Fehlende Rechte für Anfrage</Text>
              </>
              }
            </View>
          ) : uiState?.buttons == 'show_approve' ? (
            <View style={{ marginRight: 5 }}>
              {!acceptable 
                ? <Text style={{fontSize:16}}>Fehlende Rechte zum Akzeptieren</Text>
                : <GpjButton
                    text="Übergabe akzeptieren"
                    onPress={uebergabeApproveAndCloseDialoge}
                    enabledBackground={dsv.colors.success}
                    isDisabled={!acceptable}
                  />
              }
              {!acceptable 
                ? <Text style={{fontSize:16}}>Fehlende Rechte zum Ablehnen</Text>
                : <GpjButton
                    text="Übergabe ablehnen"
                    onPress={openRejectionDialoge}
                    enabledBackground={dsv.colors.error}
                    isDisabled={!acceptable && !rejectable}
                  />
              }
            </View>
          ) : uiState?.buttons == 'show_reject' ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={dsv.text}>Übergeben am {uiState.approvedAt.toLocaleDateString()}</Text>
              <View style={{ margin: 5, paddingBottom: 5 }}>
                {!revocable 
                  ? <Text style={{fontSize:16}}>Zurückziehen nicht möglich</Text>
                  : !acceptable
                    ? <Text style={{fontSize:16}}>Fehlende Rechte zum zurückziehen</Text>                
                    : <GpjButton
                      text="Übergabe zurückziehen"
                      onPress={openRevocationDialoge}
                      enabledBackground={dsv.colors.error}
                      isDisabled={!(acceptable && revocable)}
                    />
                }
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      {rejectionDialogeOpen && (
        <RejectionDialog
          placeholder="Ablehnungsgrund"
          buttonText="Ablehnen"
          uebergabeReject={uebergabeRejectAndCloseDialoge}
        />
      )}
      {revocationDialogeOpen && (
        <RejectionDialog
          placeholder="Rückgabegrund"
          buttonText="Zurückgeben"
          uebergabeReject={uebergabeRevokeAndCloseDialoge}
        />
      )}
    </View>
  )
}
