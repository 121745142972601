import { useTranslation } from 'react-i18next'
import { DimensionValue } from 'react-native'
import { Div } from '@expo/html-elements'

import { Role } from '@dpa/server/trpc/routers/permissions'

import { Text } from '../../../../../components/TextComponents'
import { ProgressBar } from '../../../../../components/ProgressBar'
import { okStats } from '../../../../../hooks/useOkStats'
import { dsv } from '../../../../../styles/defaults'
import { useProgressBarToDisplay } from '../../../../../hooks/roleBasedDisplay/useProgressBarToDisplay'

export interface UebergabeProgressProps {
  label: Role | 'gefüllte Pflichtfelder'
  Ver2Eng: undefined | ReturnType<typeof okStats>
  Eng2Ver: undefined | ReturnType<typeof okStats>
  Ver2PMO: undefined | ReturnType<typeof okStats>
  PMO2SuW: undefined | ReturnType<typeof okStats>
  height?: DimensionValue
}
export function UebergabeProgressRow({
  label,
  Ver2Eng,
  Eng2Ver,
  Ver2PMO,
  PMO2SuW,
  height = 12,
}: UebergabeProgressProps) {
  const { t } = useTranslation()
  const toDisplay = useProgressBarToDisplay(label == 'gefüllte Pflichtfelder' ? undefined : label)

  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        //borderColor: dsv.colors.connectGrau30,
        //borderStyle: "solid",
        //borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ width: '15%' }}>
        <Text style={{ color: dsv.colors.black }}>{label == 'Vertrieb' ? 'Vertrieb' : t(label)}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('VertriebAnEngineering') ? undefined : Ver2Eng !== undefined &&
          Ver2Eng.okCount + Ver2Eng.nokCount == 0 ? (
            <Text>nicht erforderlich</Text>
          ) : (
            <ProgressBar
              height={height}
              width={'90%'}
              value={Ver2Eng == undefined ? 0 : Ver2Eng.okPercentage}
              displayPercentage
            />
          )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('EngineeringAnVertrieb') ? undefined : Eng2Ver !== undefined &&
          Eng2Ver.okCount + Eng2Ver.nokCount == 0 ? (
            <Text>nicht erforderlich</Text>
          ) : (
            <ProgressBar
              height={height}
              width={'90%'}
              value={Eng2Ver == undefined ? 0 : Eng2Ver.okPercentage}
              displayPercentage
            />
          )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('VertriebAnPMO') ? undefined : Ver2PMO !== undefined &&
          Ver2PMO.okCount + Ver2PMO.nokCount == 0 ? (
            <Text>nicht erforderlich</Text>
          ) : (
            <ProgressBar
              height={height}
              width={'90%'}
              value={Ver2PMO == undefined ? 0 : Ver2PMO.okPercentage}
              displayPercentage
            />
          )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('PMOAnS&W') ? undefined : PMO2SuW !== undefined &&
          PMO2SuW.okCount + PMO2SuW.nokCount == 0 ? (
            <Text>nicht erforderlich</Text>
          ) : (
            <ProgressBar
              height={height}
              width={'90%'}
              value={PMO2SuW == undefined ? 0 : PMO2SuW.okPercentage}
              displayPercentage
            />
          )}
      </Div>
    </Div>
  )
}
