import { Div } from '@expo/html-elements'
import { Text } from '../../../../../components/TextComponents'

import { dsv } from '../../../../../styles/defaults'

export interface UebergabeHeaderRowProps {
  Ver2Eng_label: string
  Eng2Ver_label: string
  Ver2PMO_label: string
  PMO2SuW_label: string
}

export function UebergabeHeaderRow({
  Ver2Eng_label,
  Eng2Ver_label,
  Ver2PMO_label,
  PMO2SuW_label,
}: UebergabeHeaderRowProps) {
  return (
    <Div style={{ display: 'flex', flexDirection: 'row' }}>
      <Div style={{ width: '15%' }} />
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Ver2Eng_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Eng2Ver_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Ver2PMO_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{PMO2SuW_label}</Text>
      </Div>
    </Div>
  )
}
