import { Div } from '@expo/html-elements'
import { Text } from '../../../../../components/TextComponents'

import { dsv } from '../../../../../styles/defaults'

export interface UebergabeRowProps {
  label: string
  Ver2Eng: string
  Eng2Ver: string
  Ver2PMO: string
  PMO2SuW: string
}
export function UebergabeRow({ label, Ver2Eng, Eng2Ver, Ver2PMO, PMO2SuW }: UebergabeRowProps) {
  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderColor: dsv.colors.connectGrau30,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ width: '15%' }}>
        <Text style={{ color: dsv.colors.black }}>{label}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Ver2Eng}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Eng2Ver}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Ver2PMO}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{PMO2SuW}</Text>
      </Div>
    </Div>
  )
}
