import { Div } from '@expo/html-elements'
import { Text } from '../../TextComponents'
import { useTranslation } from 'react-i18next'
import { addPrefix  } from '../../../locales/translationKeys'
import { Ionicons as Icon } from '@expo/vector-icons'
import { InputText } from '../../form/InputFields'
import { DataListData } from './dataAndTypes'

export type DataListFiltersEntryProps<
  TData extends DataListData<TKeys>,
  TKeys extends string
> = {
  //data: undefined | Array<TData>,
  deactivateThisFilter: () => void
  setThisFieldFilter: (filter: string | undefined) => void
  field:TKeys
  active: boolean
  filter: undefined|string
}

export function DataListFiltersEntry<
  TData extends DataListData<TKeys>,
  TKeys extends string
>({
  //data,
  deactivateThisFilter,
  setThisFieldFilter,
  field,
  active,
  filter,
} : DataListFiltersEntryProps<TData, TKeys>){

  const {t} = useTranslation()
  if(!active){
    console.log("not active")
    return <></>
  }
  return (
    <Div 
      style={{
        padding: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }} 
      key={field}
    >
      <Div 
        style={{
          height: 20,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }} 
        key={field}
      >
        <Text style={{flexGrow:1}}>{t(addPrefix("tableHeaders", field)) }</Text>
        <Div 
          onPointerDown={deactivateThisFilter}
          style={{
            width: 20,
            height: 20,
            marginRight: 10,
            cursor: 'pointer',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Icon name="close" size={18} />
        </Div>
      </Div>
      <InputText fieldId={`filter_input_${field}`} strValue={filter ?? ''} setStrValue={setThisFieldFilter}/>
    </Div>

  )
}
