/* eslint max-len: 0 */ // --> OFF

import { Div } from '@expo/html-elements'

import { useAkteContext } from '../../../../../hooks/context/useAkteContext'

import { useAllPermissions } from '../../../../../hooks/useAllPermissions'
import { useMemo } from 'react'
import { applyGateConditions, process_akte } from '@dpa/common/dist'
import { okStats } from '../../../../../hooks/useOkStats'
import { useProcessGateContext } from '../../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../../hooks/useAkteSalesLines'
import { SubCard } from '../../../../../components/SubCard'
import { akteDisplayConditions } from '../../akteDisplayConditions'

import { UebergabeRow } from './UebergabeRow'
import { UebergabeHeaderRow } from './UebergabeHeaderRow'
import { UebergabeSpacerRow } from './UebergabeSpacerRow'
import { UebergabeProgressRow } from './UebergabeProgressRow'
import { formatAkzeptiertAm, formatDate, formatReason } from './utils'

export function SubCardUebergaben() {
  const akte = useAkteContext()

  const salesLines = useAkteSalesLines(akte.identifier)

  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const isLoading = akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading

  const progress = useMemo(() => {
    if (isLoading) {
      return undefined
    }

    const projectType = process_akte.processType(akte.serverState)
    const states = ['UebergabeAnProjektierung', 'UebergabeAnVertrieb', 'UebergabeAnPMO', 'UebergabeAnSW'] as const
    const requiredPerState = Object.fromEntries(
      Object.entries(processGateCtx.processGates[projectType]).map(([step, gateFields]) => {
        const requiredFields = applyGateConditions(gateFields, akte.localState, akteDisplayConditions)

        return [
          step,
          Object.entries(requiredFields)
            .filter(([_fieldName, isRequired]) => String(isRequired) === 'required')
            .map(([fieldName, _isRequired]) => fieldName),
        ]
      })
    )

    const relevantFields = Object.fromEntries(
      Object.entries(allPermissions.permissions!).map(([rolle, permissions]) => {
        const writableFields = Object.entries(permissions.permissions)
          .filter(([_name, p]) => p?.write)
          .map(([name, _]) => name)

        return [rolle, writableFields]
      })
    )
    const p = Object.fromEntries(
      states.map((s) => [
        s,
        Object.fromEntries(
          Object.keys(relevantFields).map((rf) => {
            return [
              rf,
              okStats(
                Object.fromEntries(
                  relevantFields[rf]
                    .filter((f) => requiredPerState[s].includes(f))
                    .map((f) => [
                      f,
                      {
                        ok: akte.serverState[f as keyof typeof akte.serverState] != null,
                        notNullish: akte.serverState[f as keyof typeof akte.serverState] != null,
                      },
                    ])
                )
              ),
            ]
          })
        ),
      ])
    )
    return p
  }, [isLoading, akte, processGateCtx.processGates, allPermissions.permissions])

  const totalProgress = useMemo(() => {
    if (progress == undefined) {
      return undefined
    }
    const progressPerStep = Object.entries(progress).map(([step, progressPerGroup]) => {
      return [
        step,
        Object.values(progressPerGroup).reduce(
          (a, b) => ({
            okCount: a.okCount + b.okCount,
            nokCount: a.nokCount + b.nokCount,
          }),
          { okCount: 0, nokCount: 0 }
        ),
      ] as [
        string,
        {
          okCount: number
          nokCount: number
        },
      ]
    })

    const progressPercentagePerStep = progressPerStep.map(([step, progress]) => [
      step,
      {
        ...progress,
        okPercentage:
          progress.nokCount + progress.okCount === 0
            ? 100
            : (100 / (progress.nokCount + progress.okCount)) * progress.okCount,
      },
    ])

    return Object.fromEntries(progressPercentagePerStep)
  }, [progress])

  return (
    <>
      <SubCard title="Übergaben">
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <UebergabeHeaderRow
            Ver2Eng_label="Vertrieb an Engineering"
            Eng2Ver_label="Engineering an Vertrieb"
            Ver2PMO_label="Vertrieb an Projektleitung"
            PMO2SuW_label="Projektleitung an S&W"
          />
          <UebergabeSpacerRow size="small" />
          <UebergabeRow
            label={'Beantragt am'}
            Ver2Eng={formatDate(akte.serverState?.Process_Uebergabe_Projektierung_requested)}
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_requested)}
            Ver2PMO={formatDate(akte.serverState?.Process_Uebergabe_PMO_requested)}
            PMO2SuW={formatDate(akte.serverState?.Process_Uebergabe_SW_requested)}
          />
          <UebergabeRow
            label={'Genehmigt am'}
            Ver2Eng="N/A"
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_preapproved)}
            Ver2PMO="N/A"
            PMO2SuW="N/A"
          />
          <UebergabeRow
            label={'Akzeptiert am'}
            Ver2Eng={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_Projektierung_requested,
              akte.serverState?.Process_Uebergabe_Projektierung_rejected,
              akte.serverState?.Process_Uebergabe_Projektierung_approved
            )}
            Eng2Ver={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_Vertrieb_requested,
              akte.serverState?.Process_Uebergabe_Vertrieb_rejected,
              akte.serverState?.Process_Uebergabe_Vertrieb_approved
            )}
            Ver2PMO={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_PMO_requested,
              akte.serverState?.Process_Uebergabe_PMO_rejected,
              akte.serverState?.Process_Uebergabe_PMO_approved
            )}
            PMO2SuW={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_SW_requested,
              akte.serverState?.Process_Uebergabe_SW_rejected,
              akte.serverState?.Process_Uebergabe_SW_approved
            )}
          />

          <UebergabeRow
            label={'Zurückgegeben am'}
            Ver2Eng={formatDate(akte.serverState?.Process_Uebergabe_Projektierung_revoked)}
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_revoked)}
            Ver2PMO={formatDate(akte.serverState?.Process_Uebergabe_PMO_revoked)}
            PMO2SuW={formatDate(akte.serverState?.Process_Uebergabe_SW_revoked)}
          />

          <UebergabeRow
            label={'Ablehnungsgrund'}
            Ver2Eng={formatReason(
              akte.serverState?.Process_Uebergabe_Projektierung_approved,
              akte.serverState?.Process_Uebergabe_Projektierung_rejectionComment
            )}
            Eng2Ver={formatReason(
              akte.serverState?.Process_Uebergabe_Vertrieb_approved,
              akte.serverState?.Process_Uebergabe_Vertrieb_rejectionComment
            )}
            Ver2PMO={formatReason(
              akte.serverState?.Process_Uebergabe_PMO_approved,
              akte.serverState?.Process_Uebergabe_PMO_rejectionComment
            )}
            PMO2SuW={formatReason(
              akte.serverState?.Process_Uebergabe_SW_approved,
              akte.serverState?.Process_Uebergabe_SW_rejectionComment
            )}
          />

          <UebergabeRow
            label={'Rückgabegrund'}
            Ver2Eng={formatReason(
              akte.serverState?.Process_Uebergabe_Projektierung_revoked,
              akte.serverState?.Process_Uebergabe_Projektierung_revocationComment
            )}
            Eng2Ver={formatReason(
              akte.serverState?.Process_Uebergabe_Vertrieb_revoked,
              akte.serverState?.Process_Uebergabe_Vertrieb_revocationComment
            )}
            Ver2PMO={formatReason(
              akte.serverState?.Process_Uebergabe_PMO_revoked,
              akte.serverState?.Process_Uebergabe_PMO_revocationComment
            )}
            PMO2SuW={formatReason(
              akte.serverState?.Process_Uebergabe_SW_revoked,
              akte.serverState?.Process_Uebergabe_SW_revocationComment
            )}
          />

          <UebergabeSpacerRow size="large" />
          <UebergabeProgressRow
            label="gefüllte Pflichtfelder"
            Ver2Eng={totalProgress == undefined ? undefined : totalProgress['UebergabeAnProjektierung']}
            Eng2Ver={totalProgress == undefined ? undefined : totalProgress['UebergabeAnVertrieb']}
            Ver2PMO={totalProgress == undefined ? undefined : totalProgress['UebergabeAnPMO']}
            PMO2SuW={totalProgress == undefined ? undefined : totalProgress['UebergabeAnSW']}
            height={18}
          />

          <UebergabeSpacerRow size="normal" />

          <UebergabeProgressRow
            label="Vertrieb"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Vertrieb']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Vertrieb']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Vertrieb']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Vertrieb']}
          />
          {/*
          <UebergabeProgressRow
            label="Innendienst"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Innendienst']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Innendienst']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Innendienst']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Innendienst']}
          />
          */}
          <UebergabeProgressRow
            label="Projektierung"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Projektierung']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Projektierung']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Projektierung']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Projektierung']}
          />
          <UebergabeProgressRow
            label="PMO"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['PMO']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['PMO']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['PMO']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['PMO']}
          />
          <UebergabeProgressRow
            label="Projekt Support"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Projekt Support']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Projekt Support']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Projekt Support']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Projekt Support']}
          />
          {/*
          <UebergabeProgressRow
            label="Finanzen"
            Ver2Eng={progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Finanzen']}
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Finanzen']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Finanzen']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Finanzen']}
          />
          <UebergabeProgressRow
            label="Service & Wartung"
            Ver2Eng={
              progress == undefined ? undefined : progress['UebergabeAnProjektierung']['Service & Wartung']
            }
            Eng2Ver={progress == undefined ? undefined : progress['UebergabeAnVertrieb']['Service & Wartung']}
            Ver2PMO={progress == undefined ? undefined : progress['UebergabeAnPMO']['Service & Wartung']}
            PMO2SuW={progress == undefined ? undefined : progress['UebergabeAnSW']['Service & Wartung']}
          />*/}
        </Div>
      </SubCard>
    </>
  )
}
